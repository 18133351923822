#top-bar{
    height: 60px;
    background-color: #ffffff;
    box-shadow: #dedede 0 3px 3px;
    z-index: 20;
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #202020;
    padding: 0 20px;
    line-height: 60px;
    font-size: 18px;
}

.top-bar-left{

}

.top-bar-right{

}

.top-bar-right-link{
    font-size: 14px;
    margin-left: 20px;
    text-decoration: none;
    outline: none;
    color: #202020;
}

.top-bar-right-link:hover{
    color: #95bfc4;
}