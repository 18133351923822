#online {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.online-left-bar{
    width: 200px;
    height: 100%;
    background-color: #fff;
}

.online-left-bar ul {
    margin: 0;
    padding: 20px;
}

.online-left-bar-li{
    list-style: none;
    margin-bottom: 10px;
    cursor: pointer;
}

.online-left-bar-li:hover {
    color: #95bfc4;
}