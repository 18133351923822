.record-item {
    width: 100%;
    height: 150px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.record-item > img {
    margin: 10px;
    width: 130px;
    height: 130px;
}


.record-item-right{
    width: 100%;
    padding: 10px 10px 10px 0;
    /*padding: ;*/
    display: flex;
    justify-content: space-between;
}

.record-item-right-info-left{

}

.record-item-right-info-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
}

.record-item-id{
    font-size: 16px;
    font-weight: bold;
}

.record-item-time{
    color: #909090;
}

.record-item-download-icon{
    margin-right: 10px;
}