#AcTrace-panel{
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
}

.skeleton-record-item{

    width: 100%;
    display: block;

}